import React from "react";
import { graphql } from "gatsby";
import { NotFoundPageProps } from "_types/INotFoundPage";
import { Page404 } from "presentation/screens";
import { ThemeProvider } from "presentation/Layout/ThemeProvider";
import { TemplatePage } from "presentation/Layout/TemplatePage";
import {
  getDataFooter,
  getDataMenu,
  getDataSearchMenu
} from "_utils/assemblePagesData";
import SEO from "presentation/Seo";
import { SITE_NAME } from "_utils/constants";

export default function NotFoundPage({ data }: NotFoundPageProps) {
  const { menu, title, urlKnowMore } = getDataMenu({
    data: data?.menu?.data?.body
  });
  const isExistSpecialties =
    data?.specialties?.nodes && data?.specialties?.nodes.length > 0;
  const specialties = isExistSpecialties
    ? data?.specialties.nodes.map(item => ({
        label: item?.data?.nome_da_especialidade.richText[0].text,
        icon: item?.data?.icone_da_especialidade?.url,
        url: item?.url
      }))
    : [];
  const searchMenu = getDataSearchMenu({
    searchExams: data?.searchExams,
    ctaContato: data?.ctaContato,
    allExams: data?.allExams,
    urlSearchExam: menu?.link_busca_exames?.uid
  });
  const footerData = getDataFooter({ data: data?.menu?.data?.body });
  const dataSEO = {
    nofollow: false,
    noindex: false,
    telefone1: data?.contact?.data?.telefone_1?.text,
    email: data?.contact?.data?.email?.text,
    openGraph: {
      url: "/404",
      og_title: { text: "Página não encontrada | Sollutio Diagnósticos" },
      og_description: {
        text: "Página não encontrada. Esta página mudou de URL, foi removida ou nunca existiu. "
      },
      site_name: SITE_NAME
    },
    images: []
  };

  return (
    <ThemeProvider>
      <SEO {...dataSEO} />
      <TemplatePage
        menu={menu}
        title={title}
        urlKnowMore={urlKnowMore}
        specialties={specialties}
        searchExams={searchMenu}
        initialStyle="white"
        footerData={footerData}
        contact={data?.contact}
      >
        <Page404 goSearchExam={searchMenu?.urlSearchExam ?? "exames"} />
      </TemplatePage>
    </ThemeProvider>
  );
}

export const query = graphql`
  query PageNotFound {
    menu: prismicMenuFooter {
      ...MenuFragment
    }
    searchExams: prismicBuscarExames {
      ...BuscarExamesFragment
    }
    contact: prismicContato {
      ...Contact
    }

    allExams: allPrismicExames {
      nodes {
        data {
          body {
            ... on PrismicExamesDataBodyHero {
              id
              items {
                sinonimo {
                  text
                }
              }
              primary {
                codigo_sollutio {
                  text
                }
                nome_do_exame {
                  text
                }
                codigo_tuss {
                  text
                }
              }
            }
          }
        }
        uid
      }
    }

    specialties: allPrismicEspecialidade(
      sort: { order: ASC, fields: data___nome_da_especialidade___text }
    ) {
      nodes {
        data {
          nome_da_especialidade {
            richText
          }
          icone_da_especialidade {
            url
          }
        }
        url: uid
      }
    }
  }
`;
